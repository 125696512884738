.school-link:hover {
  text-decoration: underline;
}
.headshot {
  height: 200px;
  width: auto;
}

.app-screenshot {
  border: 1px solid #6b6b6b;
}

.head-yellow-text {
  color: #ffc107;
}

// .code-bg-mobile {
//   @media (max-width: 767px) {
//     display: block;
//   }
//   @media (min-width: 768px) {
//     display: none;
//   }
// }
// .code-bg {
//   @media (max-width: 767px) {
//     display: none;
//   }
//   @media (min-width: 768px) {
//     display: block;
//   }
// }